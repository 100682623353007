import React from 'react';
import { Router } from '@reach/router';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import Ranking from 'sales/Ranking';

export default (props) => (
  <Layout {...props} hideNodeSelector>
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Router>
        <Ranking path="/sales/ranking/:salesFld" />
      </Router>
    </ReactQueryConfigProvider>
  </Layout>
);
